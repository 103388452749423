<template>
  <div class="dashed" />
</template>

<script setup lang='ts'>

</script>

<style>
.dashed {
    background: linear-gradient(to right, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08) 12.6px, transparent 12.6px, transparent);
    background-size: 21px 100%;
}
</style>
