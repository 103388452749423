import { sendRequest } from './everpay-js/api'
import { Token } from './everpay-js'
import redstone from 'redstone-api'
import random from 'lodash/random'
import axios from 'axios'
import { isProd } from '@/constants'
import {
  CurrencyPriceItem,
  NftInfo,
  Collection,
  ArNftInfo,
  ArCollectionNfts
} from '@/store/state'
import { StressTestInfo, StressTestUserStats, RedPacketsInfo, ClaimedTx, CliamParams } from './types'
const apiHost = `https://nft${isProd ? '' : '-dev'}.permaswap.network`
const redPacket = `https://red${isProd ? '' : '-dev'}.everpay.io`
export const getMarketPrices = async (
  currency: string,
  tokens: Token[]
): Promise<CurrencyPriceItem[]> => {
  const symbols = tokens.map((t) => t.symbol)
  const priceStack = await redstone.getPrice(symbols)
  return tokens.map((t) => {
    return {
      symbol: t.symbol,
      price:
      priceStack[t.symbol] != null && (t.symbol !== 'ARDRIVE')
        ? priceStack[t.symbol].value.toString()
        : t.symbol === 'ACNH'
          ? '0.144927536'
          : '0'
    }
  })
}

interface GetPstTokenBalanceParams {
  contractId: string
  address: string
}

export const getPstTokenBalance = async (
  params: GetPstTokenBalanceParams
): Promise<string> => {
  const { contractId, address } = params
  // 随机使用 API KEY 避免次数限制
  // 免费版本是每天 2000个请求，然后速率限制是 3call/s
  // https://trello.com/c/tS5yluYx
  const XAPIKEYs = [
    '0fc9994f96adcd5f4f96bea375ee30931fe09c2dcac509a270a2724f359ff083',
    '1c75136bf248d14aa875b9850f3a32a2ddc1dae4816e0c2fd33d04a017c18a5c',
    '3aac25c9c450fbe67b957cf63b3029081dca2d99ed9d04b30dcc3e96ced89cd3',
    '04ae7c896078a0816ccdb5d0d9ccc269d401701bc039fd40709f5e0a4a858e41'
  ]
  const XAPIKEY = XAPIKEYs[random(0, XAPIKEYs.length - 1)]

  const data = JSON.stringify({
    query: `query address($hash: String!, $chain: String!, $network: String!) {
      address(hash: $hash, chain: $chain, network: $network) {
        hash
        balance
        discoveredTimestamp
        txCount
        internalsCount
        checksumAddress
        oldChecksumAddress
        tokens {
          hash
          name
          symbol
          decimals
          score
          balance
        }
      }
    }`,
    variables: { hash: address, chain: 'arweave', network: 'mainnet' }
  })

  const config = {
    method: 'POST',
    url: 'https://api.viewblock.io/graphql',
    headers: {
      'X-APIKEY': XAPIKEY,
      'Content-Type': 'application/json'
    },
    data: data
  }

  const response = await axios(config as any)
  const result = response.data
  const found = result.data.address.tokens.find(
    (t: any) => t.hash === contractId
  )
  if (found != null) {
    return found.balance
  }
  return '0'
}

export const getEthNfts = async (): Promise<NftInfo[]> => {
  const url = `${apiHost}/info`
  const result = await sendRequest({
    url,
    method: 'GET'
  })

  return result.data
}

export const getArNfts = async (): Promise<ArNftInfo[]> => {
  const url = `${apiHost}/ar/info`
  const result = await sendRequest({
    url,
    method: 'GET'
  })

  return result.data
}

export const getHotNfts = async (): Promise<NftInfo[]> => {
  const url = `${apiHost}/hot`
  const result = await sendRequest({
    url,
    method: 'GET'
  })
  return result.data
}

export const getEthCollections = async (): Promise<Collection[]> => {
  const url = `${apiHost}/collections`
  const result = await sendRequest({
    url,
    method: 'GET'
  })
  return result.data
}

export const getEthCollectionNFTs = async (
  collectionName: string
): Promise<NftInfo[]> => {
  const url = `${apiHost}/collection/${collectionName}/nft`
  const result = await sendRequest({
    url,
    method: 'GET'
  })
  return result.data
}

export const getSearchEthNFTs = async (query: string): Promise<NftInfo[]> => {
  const url = `${apiHost}/query/${query}`
  const result = await sendRequest({
    url,
    method: 'GET'
  })
  return result.data
}

export const getArCollections = async (): Promise<ArCollectionNfts[]> => {
  const url = `${apiHost}/ar/collections`
  const result = await sendRequest({
    url,
    method: 'GET'
  })
  return result.data
}

export const getArCollectionNFTs = async (
  collectionName: string
): Promise<ArNftInfo[]> => {
  const url = `${apiHost}/ar/collection/${collectionName}/nft`
  const result = await sendRequest({
    url,
    method: 'GET'
  })
  return result.data
}

export const getSearchArNFTs = async (query: string): Promise<ArNftInfo[]> => {
  const url = `${apiHost}/ar/query/${query}`
  const result = await sendRequest({
    url,
    method: 'GET'
  })
  return result.data
}
export const getSearchAddrNFTs = async (query: string): Promise<NftInfo[]> => {
  const url = `${apiHost}/query_by_addr/${query}`
  const result = await sendRequest({
    url,
    method: 'GET'
  })
  return result.data
}

export const getLimitIP = async (): Promise<boolean> => {
  const url = `https://api${isProd ? '' : '-dev'}.everpay.io/limit_ip`
  const result = await sendRequest({
    url,
    method: 'GET'
  })
  return result.data.limit
}

export const getStressTestInfo = async (): Promise<StressTestInfo> => {
  const url = 'https://auction-dev.permaswap.network/info'
  const result = await sendRequest({
    url,
    method: 'GET'
  })
  return result.data
}
export const getStressTestUserStats = async (
  addr: string
): Promise<StressTestUserStats> => {
  const url = `https://auction-dev.permaswap.network/stats?user=${addr}`
  const result = await sendRequest({
    url,
    method: 'GET'
  })
  return result.data
}

export const getRedPacketInfo = async (): Promise<RedPacketsInfo> => {
  const url = `${redPacket}/info`
  const result = await sendRequest({
    url,
    method: 'GET'
  })
  return result.data
}

export const verifyRedPacketAddress = async (redpacketUUID: string, address: string): Promise<ClaimedTx> => {
  const url = `${redPacket}/claimed?redpacket_uuid=${redpacketUUID}&claimed_by=${address}`
  const result = await sendRequest({
    url,
    method: 'GET'
  })
  return result.data.claimedTx
}

export const cliamRedpacket = async (params: CliamParams): Promise<ClaimedTx> => {
  const url = `${redPacket}/claim`
  const result = await sendRequest({
    url,
    method: 'POST',
    data: params
  })
  return result.data.claimedTx
}
