import { ChainType, EverpayInfo } from '@/libs/everpay-js'

export enum ConnectAppName {
  'MetaMask' = 'MetaMask',
  'imToken' = 'imToken',
  'WalletConnect' = 'Wallet Connect',
  'CoinbaseWallet' = 'Coinbase Wallet',
  'BitKeep' = 'BitKeep',
  'HuobiWallet' = 'Huobi Wallet',
  'ArConnect' = 'ArConnect',
  'Finnie' = 'Finnie',
  'MathWallet' = 'Math Wallet',
  'Safeheron' = 'Safeheron'
}

export interface StateBalanceItem {
  chainType: string
  symbol: string
  balance: string
  tag?: string
  address?: string
}

export interface DepositPendingItem {
  tokenTag: string
  chainType: ChainType
  amount: string
  chainTxHash: string
  time: number
  // AR 没有 nonce
  nonce?: number
  step: number
  everHash?: string
}

export interface DepositPending {
  [account: string]: DepositPendingItem[]
}

export interface WithdrawPendingItem {
  tokenTag: string
  chainType: ChainType
  amount: string
  everHash: string
  time: number
  step: number
  quickWithdrawErr?: string
  quickMode?: boolean
}

export interface WithdrawPending {
  [account: string]: WithdrawPendingItem[]
}

export interface Contact {
  name: string
  account: string
}

export interface EditContactParams {
  index: number
  contact: Contact
}

export interface ContactStack {
  [account: string]: Contact[]
}

export interface CurrencyPriceItem {
  symbol: string
  price: string
}
export interface Stats {
  count: number
  floor_price: number
  num_owners: number
}
export interface Collection {
  created_date?: string
  image_url?: string
  name: string
  slug?: string
  stats?: Stats
}
export interface NftInfo {
  tokenId: string
  contractAddr: string
  auctionType: string
  collection: Collection
  collectionName: string
  collectionNameDes: string
  imageUrl: string
  minOffer: string
  minOfferSymbol: string
  name: string
  nameDes: string
  owner: string
  ownerLink: string
  permaLink: string
  price: string
  priceSymbol: string
  topOffer: string
  topOfferSymbol: string
  timestamp: number
  dataUrl?: string
}
export interface ArCollectionNfts {
  name: string
}
export interface ArNftInfo {
  contractAddr: string
  tokenId: string
  owner: string
  name: string
  collectionName: string
  imageUrl: string
  timestamp: number
  dataUrl?: string
}

export interface State {
  account: string
  currency: string
  connectModalVisible: boolean
  connectingModalVisible: boolean
  connectAppName: ConnectAppName
  quickWithdrawMode: boolean
  accChainType: ChainType
  everpayInfo: EverpayInfo
  swapInfo: {
    tokenList: string[]
    status: string
  }
  everpayBalances: StateBalanceItem[]
  chainBalances: StateBalanceItem[]
  depositPending: DepositPending
  withdrawPending: WithdrawPending
  currencyPrices: CurrencyPriceItem[]
  nftauction: Auction
  // 已经弃用
  contacts?: string[]
  contactStack: ContactStack
  arNfts: ArNftInfo[]
  ethNfts: NftInfo[]
  hotNfts: NftInfo[]
  ethBatchNfts: Collection[]
  arBatchNfts: ArCollectionNfts[]
  isLpAddressSame: boolean
}
export interface Auction {
  isStart: Boolean
  isEnd: Boolean
}

export const defaultState: State = {
  account: '',
  currency: 'USD',
  accChainType: ChainType.ethereum,
  connectModalVisible: false,
  connectingModalVisible: false,
  connectAppName: ConnectAppName.MetaMask,
  quickWithdrawMode: false,
  everpayInfo: {
    lockers: {},
    ethChainID: '',
    feeRecipient: '',
    owner: '',
    tokenList: [],
    everRootHash: '',
    rootHash: ''
  },
  everpayBalances: [],
  chainBalances: [],
  depositPending: {},
  withdrawPending: {},
  swapInfo: {
    tokenList: [],
    status: ''
  },
  currencyPrices: [],
  contactStack: {},
  nftauction: {
    isStart: false,
    isEnd: false
  },
  arNfts: [],
  ethNfts: [],
  hotNfts: [],
  ethBatchNfts: [],
  arBatchNfts: [],
  isLpAddressSame: false
}
