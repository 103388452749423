import { sendRequest } from '@/libs/everpay-js/api'
import { isProd, isMobile } from '@/constants'
import { Token } from '@/libs/everpay-js'
import { PenaltyAccount } from './interface'
import { permaMessage } from '@/components/Message'
import {
  genTokenTag,
  matchTokenTag,
  toBN,
  fromUnitToDecimal,
  fromDecimalToUnit
} from '@/libs/everpay-js/utils/util'
type fn = (...args: any[]) => any
export interface SwapParams {
  payToken: {
    value: string
    symbol: string
    chainType: string
  }
  receiveToken: {
    value: string
    symbol: string
    chainType: string
  }
  signMessage: string
  confirm: fn
}
interface GetSwapOrdersParams {
  account: string
  page: number
}
export interface OrdersResult {
  id: number
  address: string
  everHash: string
  tokenInTag: string
  tokenOutTag: string
  tokenInAmount: string
  tokenOutAmount: string
  price: string
  status: string
  timestamp: number
}
interface GetSwapOrdersResult {
  total: number
  orders: OrdersResult[]
}
// 获取有效小数位
export const validValues = (amount: string | number, place: number): string | number => {
  const toBNAmount = toBN(amount).toString()
  const [firstAmount, lastAmount = ''] = toBNAmount.split('.')
  let flag = false
  let count = ''
  let index = 0
  lastAmount.split('').forEach((item) => {
    if (index !== place) {
      if (+item > 0) {
        flag = true
      } else {
        flag = false
      }
      if (flag) {
        index++
      }
      count = count + item
    }
  })
  return +`${firstAmount}${count.length > 0 ? '.' + count : ''}`
}
/***
 * Amount ≥ 1,000,000：不显示小数位
 * 1,000,000 > Amount ≥100: 小数位 2 位
 * 100 ＞ Amount ≥ 0.0001: 小数后4位
 *  Amount＜ 0.0001：显示 有效位 4 位
 * */
export const formatAmount = (amount: string | number): string => {
  if (amount === '' || amount === undefined || amount === null) return ''
  const toBNAmount = toBN(amount).toString()
  let formatAmount = ''
  const [firstAmount, lastAmount = ''] = toBNAmount.split('.')
  if (+toBNAmount >= 1000000) {
    formatAmount = firstAmount
  } else if (+toBNAmount >= 100) {
    formatAmount =
      firstAmount + (lastAmount !== '' ? '.' + lastAmount.slice(0, 2) : '')
  } else if (+toBNAmount >= 0.0001) {
    formatAmount =
      firstAmount + (lastAmount !== '' ? '.' + lastAmount.slice(0, 4) : '')
  } else {
    formatAmount = validValues(amount, 4).toString()
  }
  return formatAmountThreeDigits(formatAmount)
}
/***
 *  < 1  返回有效2位小数
 *  > = 1 返回小数位两位
 */
export const anchoredValues = (amount: string | number): string => {
  if (amount === '' || amount === undefined || amount === null) return ''
  if (amount === 0) return amount.toString()
  let formatAmount = ''
  const toBNAmount = toBN(amount).toString()
  const [firstAmount, lastAmount = ''] = toBNAmount.split('.')
  if (+toBNAmount < 1) {
    formatAmount = validValues(amount, 2).toString()
  } else {
    formatAmount = `${firstAmount}${
      lastAmount !== '' ? '.' + lastAmount.slice(0, 2) : ''
    }`
  }
  return toBN(formatAmount).toFormat().toString()
}

export const formatAmountThreeDigits = (amount: string | number): string => {
  return toBN(amount).toFormat().toString()
}

export const getPenaltyAccountList = async (): Promise<PenaltyAccount> => {
  const url = 'https://router0-dev.permaswap.network/penalty'
  const result = await sendRequest({
    url,
    method: 'GET',
    timeout: 5000
  })

  const formatPenaltyAccount = {}
  const keys = Object.keys(result.data.blackList)
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    const value = result.data.blackList[key]
    Reflect.set(formatPenaltyAccount, key.toLowerCase(), value)
  }
  result.data.blackList = formatPenaltyAccount
  return result.data
}

export const getSwapOrders = async (
  params: GetSwapOrdersParams
): Promise<GetSwapOrdersResult> => {
  const { account, page } = params
  const url = `https://${
    isProd ? 'router.permaswap.network' : 'router0-dev.permaswap.network'
  }/orders/${account}?page=${page}`
  const result = await sendRequest({
    url,
    method: 'GET'
  })
  return result.data
}

export const getSwapInfo = async (): Promise<string[]> => {
  const url = `https://${
    isProd ? 'router.permaswap.network' : 'router0-dev.permaswap.network'
  }/info`
  const result = await sendRequest({
    timeout: 15000,
    url,
    method: 'GET'
  })
  return result.data
}
export const getSwapTokenList = (
  tokens: Token[],
  tokenTags: string[],
  selectedPayToken?: Token
): Token[] => {
  let useTags: string[] = tokenTags
  if (selectedPayToken != null) {
    const selectedPayTokenTag = genTokenTag(selectedPayToken)
    useTags = tokenTags.filter(
      (tag) => !matchTokenTag(tag, selectedPayTokenTag)
    )
  }
  return tokens.filter((token) => {
    return useTags?.find((tag) =>
      matchTokenTag(tag, genTokenTag(token))
    ) as string
  })
}

export let socket = null as any as WebSocket

interface SendQueryParams {
  tokenIn: Token
  tokenOut: Token
  tokenInAmount: string
  address?: string
}

export interface InitSocketParams {
  handleError: any
  handleOrder: any
  handleSubmit: any
  handleOpen: any
}
export const initSocket = async (params: InitSocketParams): Promise<void> => {
  socket = new WebSocket(
    `wss://${
      isProd ? 'swap.everpay.io' : 'router0-dev.permaswap.network'
    }/wsuser`
  )
  socket.addEventListener('message', (message: any) => {
    const data = JSON.parse(message.data)
    if (data.event === 'order') {
      params.handleOrder(data)
    } else if (data.event === 'error') {
      params.handleError(data)
    } else if (data.event === 'status') {
      params.handleSubmit(data)
    }
  })
  socket.addEventListener('open', params.handleOpen)
  socket.addEventListener('close', (e) => {
    console.log(e)
    localStorage.setItem('wsClsoe', e.code.toString())
  })
}

export const sendQuery = async (
  params: SendQueryParams
): Promise<void> => {
  const { tokenIn, tokenOut, tokenInAmount, address } = params
  const data = {
    event: 'query',
    tokenIn: tokenIn.tag,
    tokenOut: tokenOut.tag,
    amountIn: fromUnitToDecimal(tokenInAmount, tokenIn.decimals),
    address: address !== '' ? address : `0x${'0'.repeat(40)}`
  }
  console.log('data', data)
  socket.send(JSON.stringify(data))
}

interface SendSubmitParams {
  address: string
  bundle: any
  tokenIn: string
  tokenOut: string
  paths: any
}

export const sendSubmit = (params: SendSubmitParams): void => {
  const { address, bundle, paths, tokenIn, tokenOut } = params
  const data = {
    event: 'submit',
    address,
    tokenIn,
    tokenOut,
    bundle,
    paths
  }
  socket.send(JSON.stringify(data))
}

export const closeConnection = (): void => {
  socket.close()
}

export const getReceiveAmountFromBundle = (
  bundle: any,
  receiveToken: Token
): string => {
  let decimalAmount = toBN(0)
  bundle.items.forEach((item: any) => {
    if (item.tag === receiveToken.tag) {
      decimalAmount = decimalAmount.plus(item.amount)
    }
  })
  return fromDecimalToUnit(decimalAmount, receiveToken.decimals)
}

type AddEvent = 'resize' | 'scroll'
export const addEvent = (event: AddEvent, fn: fn): void => {
  window.addEventListener(event, () => {
    if (!isMobile) {
      fn()
    }
  })
}

export const getPriceImpactClassName = (priceImpact: number): string => {
  if (priceImpact <= -2.5 && priceImpact > -5) {
    return 'text-permaYellow4 border-permaGreen10'
  } else if (priceImpact <= -5 && priceImpact > -20) {
    return 'text-permaRed border-permaGreen10'
  } else if (priceImpact <= -20) {
    return 'text-permaRed border-permaRed'
  }
  return 'text-permaWhite2 border-permaGreen10'
}
export const formatSiteRate = (amount: string | number): string => {
  const [first] = amount.toString().split('.')
  if (+amount >= 1000000) {
    return toBN(first).toFormat().toString()
  } else {
    return anchoredValues(amount)
  }
}
let socketTimer = null as any
export const intervalSocketState = (wait: number, initSocketFn: fn): void => {
  socketTimer !== null && clearTimeout(socketTimer)
  socketTimer = setTimeout(() => {
    if (socket.readyState === 3) {
      initSocketFn()
    }
    console.log(socket.readyState, 'socket status')
    intervalSocketState(wait, initSocket)
  }, wait)
}

export const socketMessage = (t: fn): boolean => {
  let state = false
  switch (socket.readyState) {
    case 1:
      state = true
      break
    case 3:
      state = false
      permaMessage({
        message: t('reconnect_socket'),
        type: 'warning',
        showClose: true,
        duration: 2000
      })
      break
    default:
      state = false
      break
  }
  return state
}
