import { isMobileDevice } from '@/libs/util'
import { ConnectAppName } from '@/store/state'
type fn = (...args: any[]) => any

const hosts = {
  WEBSITE: {
    dev: 'dev.everpay.io',
    prod: 'everpay.io'
  },
  APP: {
    dev: 'app-dev.everpay.io',
    prod: 'app.everpay.io'
  },
  FARM: {
    dev: 'farm-dev.everpay.io',
    prod: 'farm.everpay.io'
  },
  SCAN: {
    dev: 'scan-dev.everpay.io',
    prod: 'scan.everpay.io'
  },
  SWAP: {
    dev: 'app-dev.permaswap.network',
    prod: 'app.permaswap.network'
  },
  REDPACKET: {
    dev: 'red.dev.permaswap.network',
    prod: 'red.permaswap.network'
  }
}

const VUE_APP_PROJECT_NAME = process.env.VUE_APP_PROJECT_NAME as 'WEBSITE' | 'APP' | 'FARM' | 'SCAN' | 'SWAP' | 'REDPACKET'
export const isEverpayOfficialWebsite = process.env.VUE_APP_PROJECT_NAME === 'WEBSITE'
export const isEverpayApp = process.env.VUE_APP_PROJECT_NAME === 'APP'
export const isEverpayFarm = process.env.VUE_APP_PROJECT_NAME === 'FARM'
export const isEverpayScan = process.env.VUE_APP_PROJECT_NAME === 'SCAN'
export const isEverpaySwap = process.env.VUE_APP_PROJECT_NAME === 'SWAP'
export const isProd = window.location.host.startsWith((hosts[VUE_APP_PROJECT_NAME]).prod)
export const everpayAppHost = isEverpayApp ? '' : `https://${hosts.APP[isProd ? 'prod' : 'dev']}`
export const everpayOfficialWebsiteHost = isEverpayOfficialWebsite ? '/' : `https://${hosts.WEBSITE[isProd ? 'prod' : 'dev']}`
export const everpayFarmHost = isEverpayFarm ? '/' : `https://${hosts.FARM[isProd ? 'prod' : 'dev']}`
export const everpayScanHost = isEverpayScan ? '' : `https://${hosts.SCAN[isProd ? 'prod' : 'dev']}`

export const savedI18nStorageKey = 'saved-i18n'

export const CONFIRM_SIGN_MODAL_EVENT = 'CONFIRM_SIGN_MODAL_EVENT'

export const INFURA_ID = 'b6ac62371f004aa2beeb1790f7dcf09f'

export const isMobile = isMobileDevice()
export const defaultRedPacketUUID = isProd ? '13d56277-6798-4b9b-ba03-2fcfc376966b' : 'b676d0f7-1f61-47e8-9c34-d3a15fd37f3c'
export const CURRENCY_SYMBOL_STACK = {
  CNY: '¥',
  USD: '$'
}

export interface ConfirmSignModalItem {
  label: string
  value: string
  anotherValue?: string
  isMessage?: boolean
  isStrong?: boolean
  logoPrefixPath?: string
}
export interface ConfirmSignModalParams {
  title: string
  isLarge?: boolean
  items: ConfirmSignModalItem[]
  confirm: fn
  close: fn
}

export const ImgConnectAppName = {
  [ConnectAppName.imToken]: 'imToken.png',
  [ConnectAppName.MetaMask]: 'MetaMask.png',
  [ConnectAppName.WalletConnect]: 'Wallet Connect.png',
  [ConnectAppName.CoinbaseWallet]: 'Coinbase Wallet.png',
  [ConnectAppName.BitKeep]: 'BitKeep.png',
  [ConnectAppName.HuobiWallet]: 'Huobi Wallet.png',
  [ConnectAppName.Finnie]: 'Finnie.svg',
  [ConnectAppName.ArConnect]: 'ArConnect.png',
  [ConnectAppName.MathWallet]: 'MathWallet.png',
  [ConnectAppName.Safeheron]: 'Safeheron.png'
}

export const InstallEthereumWalletLink = {
  [ConnectAppName.MetaMask]: 'https://metamask.io/',
  [ConnectAppName.BitKeep]: 'https://bitkeep.com/',
  [ConnectAppName.Safeheron]: 'https://www.safeheron.com/'
}

export const InstallArweaveWalletLink = {
  [ConnectAppName.BitKeep]: 'https://bitkeep.com/',
  [ConnectAppName.Finnie]: 'https://koii.network/getFinnie',
  [ConnectAppName.ArConnect]: 'https://arconnect.io/',
  [ConnectAppName.MathWallet]: 'https://mathwallet.org/'
}

export const fifaArgTag = isProd ? 'bsc-arg-0xb5eadfdbdb40257d1d24a1432faa2503a867c270' : 'bsc-arg-0x7846cf6e181bb5c909d6010d15af5fffd3b61229'
export const fifaFraTag = isProd ? 'bsc-fra-0xeb042ffdabc535de2716c6b51a965f124050d4e1' : 'bsc-fra-0xa98242557818f0135b2381893caec3d4a64f88e5'

export const deleteTag = [
  fifaArgTag,
  fifaFraTag
]
