import {
  ElIcon
} from 'element-plus'

const components = [
  ElIcon
]

export const importElementPlus = (app: any): void => {
  components.forEach(component => {
    app.component(component.name, component)
  })
}
