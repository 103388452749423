<template>
  <div class="md:absolute md:right-0 md:px-0 md:left-auto md:top-50px top-20 fixed flex sm:justify-end justify-center right-0 px-4 w-full z-20">
    <transition name="fade">
      <slot />
    </transition>
  </div>
</template>

<script setup lang='ts'>

</script>

<style>

</style>
