import { createRouter, createWebHistory } from 'vue-router'

import RedPacket from '../pages/RedPacket.vue'
const routes = [
  {
    path: '/:uuid?',
    name: 'redpacket',
    component: RedPacket
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})
router.afterEach(() => {
  document.documentElement.scrollTop = 0
})
export default router
