const messages = {
  app: 'App',
  wallet: '錢包',
  bridge: '跨鏈',
  sdk: 'SDK',
  light_paper: '白皮書',
  NFT_auction: 'NFT拍賣',
  resources: '資源',
  explorer: '瀏覽器',
  lang: '繁體中文',
  done: '完成',
  launch_app: '立即體驗',
  slogan: '實時代幣協議',
  account: 'everPay 賬戶',
  use_app_connect: '使用 {connectAppName} 連接',
  products: '產品',
  develop: '開發',
  v3_farm: '流動性挖礦',
  documentation: '文檔',
  support: '用戶支持',
  help_center: '幫助中心',
  privacy_policy: '隱私條款',
  faqs: '常見問題',
  about: '關於',
  time: '時間',
  from: '來自',
  to: '轉到',
  coming_soon: '敬請期待...',
  transfer: '轉賬',
  transfer_on_everpay: 'everPay 轉賬',
  deposit: '充值',
  depositing: '充值中',
  withdraw: '提現',
  withdrawing: '提現中',
  swap: '兌換',
  transactions: '交易記錄',
  successful: '成功',
  failed: '失敗',
  internal_tx: '內部交易',
  swap_records: '兌換記錄',
  contacts: '聯繫人',
  connect: '連接錢包',
  disconnect: '斷開連接',
  connect_wallet: '連接錢包',
  connect_wallet_info: '連接Ethereum、Arweave或Moonbeam錢包以存入和管理資金。 ',
  asset: '資產',
  assets: '資產',
  assets_connect_msg: '連接錢包可以進行充值和管理資產。 ',
  receiver: '收款賬戶',
  amount: '數量',
  balance: '餘額',
  fee: '手續費',
  copy: '複製',
  edit: '編輯',
  delete: '刪除',
  add: '添加',
  modify: '修改',
  filter: '篩選',
  all: '所有',
  all_currencies: '所有幣種',
  transaction_type: '交易類型',
  balance_mark: '餘額：',
  immediately_add: '立即添加',
  network: '網絡',
  choose_network: '選擇網絡',
  choose_wallet: '選擇錢包',
  choose_token: '請選擇 Token',
  choose_contact: '請選擇聯繫人',
  spread_percent: '價差',
  insufficient: '餘額不足',
  click_deposit: '請充值',
  click_details: '點擊查看',
  complete: '完成',
  everpay_balance: 'everPay 上的餘額',
  chain_balance: '{blockchain} 鏈上餘額',
  transfer_amount: '轉賬金額',
  receive_account: '收款賬戶',
  send: '發送',
  receive: '接收',
  chain_type: '鏈類型',
  support_chain_type: '支持鏈類型',
  symbol: '資產名稱',
  max_amount: '(所有)',
  system_upgrading: '系統升級中，請等待系統升級完成',
  myaccount: '我的賬號',
  no_data: '暫無數據',
  current_account_address: '當前 everPay 賬戶地址',
  contact_exists: '聯繫人地址已存在',
  no_contacts: '尚未添加任何联係人',
  edit_contact: '修改聯繫人',
  add_contact: '添加聯繫人',
  delete_contact: '刪除聯繫人',
  contact_name: '聯繫人名稱',
  contact_account: '聯繫人地址',
  new_address_detected: '檢測到新地址，添加聯繫人',
  edit_success: '修改成功',
  add_success: '添加成功',
  delete_success: '刪除成功',
  sign_message: '簽名信息',
  confirm: '確定',
  cancel: '取消',
  total_cost: '實際花費',
  swap_out_amount: '轉出數量',
  swap_in_amount: '轉入數量',
  rate: '匯率',
  swap_success: '兌換成功',
  connect_success: '連接成功',
  disconnect_success: '取消連接成功',
  view_swap_records: '查看兌換記錄',
  swap_failed: '兌換失敗',
  swap_refund: '已退還',
  slippage: '滑點',
  swap_slippage: '設置滑點',
  not_found: '錯誤 404',
  not_found_desc: '當前頁面不存在',
  back_to_home: '返回首頁',
  transfer_receipt_support: '支持 ethereum, arweave 賬戶',
  withdraw_receipt_chaintype_support: '{chainType} 錢包地址',
  quick_withdraw_mode: '快速模式',
  quick_withdraw_mode_with_time: '快速模式（預估 {time} 秒）',
  refunded: '已退款',
  quick_withdraw_mm: '快速提現做市商：',

  quick_withdraw: '快速提現',
  quick_withdraw_data_not_inited: '數據未初始化',
  quick_withdraw_refund: '快速提現退款',
  quick_withdraw_long_time: '快速提現耗時較久，暫不支持開啟',
  expected_receive: '預計到賬',
  actual_fee: '手續費',
  actual_receive: '實際到賬',
  insufficient_quick_withdrawal: '快速提現額度不足',
  token_insufficient_quick_withdrawal: '{token} 快速提現額度不足',
  quick_withdraw_info_url: 'https://everpay.zendesk.com/hc/zh-cn/articles/4404465610137-everPay-%E5%BF%AB%E9%80%9F%E6%8F%90%E7%8E%B0',

  pls_not_transfer_to_exchange: '轉賬在 everPay 網絡進行，請勿轉賬至交易所或合約地址，無法找回！',
  pls_not_withdraw_to_exchange: '請勿提現至交易所或合約地址，無法找回！',
  pls_use_ethereum_wallet: '請先安裝 MetaMask 或使用 以太坊錢包打開',
  pls_install_arweave_wallet: '請先安裝 Arweave 錢包',
  pls_change_to_mainnet: '請切換到以太坊 mainnet 主網',
  pls_change_to_goerli: '請切換到以太坊 Goerli 測試網絡',
  copy_success: '復制成功',
  introduction_to_everPay: 'everPay 產品介紹',
  balance_info_url: 'https://everpay.zendesk.com/hc/zh-cn/articles/4405069888537-%E4%BB%80%E4%B9%88%E6%98%AF-everPay-%E4%BD%99%E9%A2%9D-',
  total_assets_of: '總資產摺合',
  invalid_name: '無效名稱',
  war_more_info: 'Wrapped AR 通過對原生 AR 進行 1:1 鎖定，併且在以太坊生成了 ERC20 的 Wrapped AR 代幣，Wrapped AR 和 AR 的價值完全相等',
  balance_more_info: '您的資產存儲在 everPay 協議上。everPay 是獨立於其他區塊鏈的協議，可以認為 everPay 是一個 Layer2 或側鏈。',
  quick_mode_more_info: 'everPay 團隊聯繫了外部做市商團隊，通過 everPay 轉賬實現了快速提現，達到節省用戶提現時間和費用的目的',
  support_token_search: '支持 Token 名稱查詢',

  swap_page: {
    swap_detail: '兌換詳情',
    submitted: '提交成功',
    confirm_swap: '確認兌換',
    view_more: '查看更多信息',
    banner_msg_title: '無滑點交易，即時成交!',
    banner_msg_tip: '100% 永續運行和 0% 搶跑損失。',
    banner_msg_title2: '參與兌換和註冊活動！',
    banner_msg_tip2: '5 個幸運運用戶將獲得 ANS 獎勵。',
    banner_msg_title3: 'ARDRIVE/AR 新交易對已經上線!',
    banner_msg_tip3: '開始交易吧。',
    banner_msg_title4: '添加 ARDRIVE/AR 流動性賺取交易手動續費。',
    banner_msg_tip4: '加入 LP 白名單吧！'
  },
  error: {
    error_zero: '金額需要大於0',
    error_less_fee: '金額需要大於手續費',
    error_receiver: '收款人地址有誤',
    error_receiver_chain_type: '錯誤的 {chainType} 地址',
    error_transfer: '轉賬失敗',
    error_withdraw: '提現失敗',
    error_deny: '交易被用戶拒絕',
    deny_connect: '用戶取消連接',
    quick_withdraw_chain_type: '不支持快速提現至 {chainType} 區塊鏈',

    less_then_zero: '轉賬金額需要大於0',
    invalid_address: '收款人地址有誤',
    err_eth_tx_minted: '交易已執行',
    err_nil_amount: '金額為空',
    err_zero_amount: '金額為零',
    err_negative_amount: '金額需要大於0',
    err_larger_amount: '金額過大',
    err_larger_data: '數據過大',
    err_insufficient_balance: '余額不足',
    err_self_transfer: '請不要給自己轉賬',
    err_invalid_amount: '金額無效',
    err_invalid_fee: '費用無效',
    err_invalid_tx_type: '交易類型無效',
    err_invalid_owner: 'owner無效',
    err_invalid_address: '地址無效',
    err_invalid_nonce: 'nonce無效',
    err_invalid_signature: '簽名無效',
    err_invalid_mint_tx: 'mint無效',
    err_invalid_locker: 'locker無效',
    err_invalid_tx_version: '交易版本無效',
    err_token_not_found: '不支持的代幣',
    err_invalid_chainid: 'chainid無效',
    err_tx_minted: '交易已打包',
    err_negative_fee: '手續費錯誤',
    err_invalid_action: 'action無效',
    err_invalid_eth_tx: 'ETH Tx 錯誤',
    err_invalid_erc20_tx: 'erc20 Tx 錯誤',
    err_invalid_eth_address: 'ETH 地址無效',
    err_invalid_account_type: '賬戶類型無效',
    err_nonce_too_low: 'nonce無效',
    err_invalid_transaction: '交易無效',
    err_limit_exceeded: '請求過於頻繁，請稍後再試',
    err_node_syncing: '等待繫統同步完成',
    err_system_upgrade: '繫統升級中，請等待繫統升級完成',

    // express
    err_no_enough_fee: '快速提現手續費不足',
    err_wallet_no_enough_fund: '做市商快速提現額度不足',
    err_unsupport_withdraw_chain_type: '不支持快速提現至 {chainType} 區塊鏈',
    err_unsupport_token: '不支持 {token} 快速提現',

    // swap
    err_no_tx_data: 'everPay 交易data 為空',
    err_invalid_tx_data: 'everPay 交易data 無效',
    err_invalid_app_id: 'app id 無效',
    err_no_tx_type: 'everPay 交易 type 為空',
    err_miss_swap_tx_data: '缺乏兌換信息',
    err_no_pool: '不支持的資金池',
    err_invalid_token: '不支持的代幣',
    err_token_out_amount_too_small: '價格變化，不滿足此數量的兌換，請重試',
    error_insufficient_fund: '此交易流動性不足',
    err_invalid_order: '錯誤的訂單數據',
    err_invalid_amout: '錯誤的訂單數量',
    err_no_order: '未找到訂單',
    err_swap_race_condition: '兌換處於競爭狀態',
    err_everpay_tx: '提交兌換交易失敗',
    err_miss_parameter: '缺少參數',
    err_unkown: '未知的兌換錯誤',
    err_insufficient_fund: '做市商資金池余額不足，請減少兌換數量',
    error_invalid_order: '錯誤的訂單',
    network_error: '網絡繁忙，請稍後再試',

    // web3
    CALL_EXCEPTION: '合約調用失敗',
    INSUFFICIENT_FUNDS: '礦工費不足',
    NETWORK_ERROR: '以太坊網絡錯誤',
    NONCE_EXPIRED: 'nonce 錯誤',
    REPLACEMENT_UNDERPRICED: '替換交易礦工費不足',
    UNPREDICTABLE_GAS_LIMIT: 'gasLimit 不足',

    INVALID_ACCOUNT_TYPE: '不支持的賬戶類型'
  },
  new_swap: {
    // new swap
    err_not_found_path: '交易失敗', // path 缺失
    err_not_found_salt: '交易失敗', // salt 缺失
    err_not_found_lp: '交易失敗 ', // lp 缺失'
    err_no_authorization: '認證失敗', // 未授權
    err_duplicate_registration: '重復註冊', // 重復註冊
    err_invalid_msg: '交易失敗', // 無效的 message
    err_invalid_token: '未支持該 Token', // 無效的 token
    err_invalid_order: '交易失敗', // 無效的 order
    err_invalid_address: '交易失敗', // 無效的 address
    err_invalid_signature: '交易失敗', // 無效的 signature
    err_invalid_paths_or_bundle: '無效的 paths 或 bundle', // 無效的 paths 或 bundle
    err_invalid_pool: '無效的流動性池', // 無效的 pool
    err_no_pool: '無效的流動性池', // 沒有 pool
    err_no_lp: '交易失敗', // 沒有 LP
    err_no_path: '流動性不足', // 流動性不足
    err_invalid_amount: '輸入數額小於最小賣出量', // 無效的 amount
    err_out_of_range: '超出範圍', // 超出範圍
    err_invalid_number: '無效的數字', // 無效的 number
    err_no_implement: '交易失敗', // 沒有執行
    err_invalid_path: '交易失敗', // 無效的 path
    err_invalid_swapouts: '交易失敗', // 無效的調換
    err_invalid_pool_paths: '交易失敗', // 無效的 pool paths
    err_invalid_price: '交易失敗', // 無效的 price
    err_invalid_liquidity: '無效的 liquidity', // 無效的 liquidity
    err_invalid_price_direction: '無效的 price direction', // 無效的 price direction
    err_invalid_fee: '無效的 fee', // 無效的 fee
    err_invalid_swap_user: '連接地址與LP 節點地址相同時，交易可能會失敗。請切換賬戶。',
    err_invalid_swap_lpuser: '交易失敗，DAPP 與 LP 地址相同。',
    err_no_lp_msg: '交易火熱🔥，請稍後重試。',
    err_blacklisted: '該賬戶異常，請稍後重試。'
  },
  // new swap
  // err_not_found_path: '交易失敗', // path 缺失
  // err_not_found_salt: '交易失敗', // salt 缺失
  // err_not_found_lp: '交易失敗 ', // lp 缺失'
  // err_no_authorization: '認證失敗', // 未授權
  // err_duplicate_registration: '重復註冊', // 重復註冊
  // err_invalid_msg: '交易失敗', // 無效的 message
  // err_invalid_token: '未支持該 Token', // 無效的 token
  // err_invalid_order: '交易失敗', // 無效的 order
  // err_invalid_address: '交易失敗', // 無效的 address
  // err_invalid_signature: '交易失敗', // 無效的 signature
  // err_invalid_paths_or_bundle: '無效的 paths 或 bundle', // 無效的 paths 或 bundle
  // err_invalid_pool: '無效的流動性池', // 無效的 pool
  // err_no_pool: '無效的流動性池', // 沒有 pool
  // err_no_lp: '交易失敗', // 沒有 LP
  // err_no_path: '流動性不足', // 流動性不足
  // err_invalid_amount: '輸入數額小於最小賣出量', // 無效的 amount
  // err_out_of_range: '超出範圍', // 超出範圍
  // err_invalid_number: '無效的數字', // 無效的 number
  // err_no_implement: '交易失敗', // 沒有執行
  // err_invalid_path: '交易失敗', // 無效的 path
  // err_invalid_swapouts: '交易失敗', // 無效的調換
  // err_invalid_pool_paths: '交易失敗', // 無效的 pool paths
  // err_invalid_price: '交易失敗', // 無效的 price
  // err_invalid_liquidity: '無效的 liquidity', // 無效的 liquidity
  // err_invalid_price_direction: '無效的 price direction', // 無效的 price direction
  // err_invalid_fee: '無效的 fee', // 無效的 fee
  // err_invalid_swap_user: '連接地址與LP 節點地址相同時，交易可能會失敗。請切換賬戶。',

  PST_WITHDARW_TO_ARWEAVE_MUST_BE_INTEGER: 'PST Token 提現至 Arweave 必須為整數',
  // NFT
  nft_error: {
    err_invalid_appId: 'appId無效',
    err_invalid_action: 'action無效',
    err_bid_self: '您已經擁有當前NFT,無需再拍',
    err_invalid_referredBy: '推薦者地址無效',
    err_invalid_nftRecipient: 'NFT收藏地址無效',
    err_invalid_tx: '交易無效',
    err_invalid_nftWallet: '藝術家錢包無效',
    err_invalid_amount: '出價金額無效',
    err_invalid_token: 'token 無效',
    err_invalid_path: 'path 無效',
    err_not_found_auctionId: '缺少NFT拍賣批次',
    err_not_found_nftId: '缺少 NFT ID',
    err_not_during_event: '拍賣時間不符',
    err_not_recaptcha: '缺少驗證',
    err_more_than_bidLimit: '不可同時競拍超過{bidLimit}個NFT',
    err_bid_too_low: '出價不得低於總價格的百分之五, 請重新出價',
    err_bid_too_high: '出價已超出總價格百分之二十, 請減少金額拍賣',
    err_more_than_ipLimit: '當前IP被限制,稍後重試',
    err_recaptcha_failed: '驗證失敗，請重試!',
    err_add_price: '很抱歉，價格已失效，當前價格為{amount}，請重新加價。'
  },
  service_not_available: '服務在您的地區無法使用',

  nfts: 'NFTs',
  pool: 'Pool',
  fifa: 'FIFA🔥',
  nav_connect: '連接錢包',
  whitepaper: '白皮書',
  select_format: '選擇格式',
  select_wallet: '選擇錢包',
  change_account: '更換',
  replicated: '復制成功',
  connecting: '連接中...',
  transaction_records: '交易記錄',
  recent_orders: '最近交易',
  transaction_success: '成功',
  transaction_failed: '失敗',
  transaction_time: '時間',
  view_more: '查看更多',
  no_more: '到底了',
  loading: '加載中',
  select_token: '選擇代幣',
  no_result: '查無結果',
  max: '最大',
  fetching_price: '正在尋找最佳兌換率…',
  enter_amount: '輸入金額',
  insufficient_balance: '{symbol} 余額不足',
  price_updated: '刷新價格',
  accept: '接受',
  expected_output: '預計獲得',
  waiting_confirmation: '等待確認',
  swap_sign_message: '簽名信息',
  more_about: '更多信息',
  please_log: '請連接錢包',
  no_data_order: '暫無數據',
  pool_message: '下載客戶端，持有認證的 NFTs，運行 LP 節點，獲得更多獎勵！',
  download_windows: '下載 Win 客戶端',
  download_mac: '下載 Mac 客戶端',
  download_linux: '支持 Linux 部署',
  deploy: '節點 部署',
  earn: '賺取',
  my_nft: '我的',

  nft: {
    certified_nft: '認證的 NFT',
    certified_nft_desc: 'everPay NFT 持有者可參與 Permaswap 先驅者挖礦',
    discover_nft: '探索併收集認證的 NFTs',
    discover_nft_desc: '收藏認證 NFTs（在 everPay 拍賣中首發的 NFTs）, 可參與 Permaswap 挖礦！',
    discover_now: '探索',
    auction: '拍賣',
    hot_nfts: '熱門 NFTs',
    view_more: '更多',
    view_more_eth: '更多 ETH NFT',
    view_more_ar: '更多 AR NFT',
    price: '價格',
    owned_by: '擁有者',
    items: '項目數',
    tab_items: '項目',
    tab_explore: '探索',
    owners: '持有者',
    floor_price: '地闆價',
    nft_on_sale: '在售的 NFT',
    price_low_to_high: '價格：從低到高',
    price_high_to_low: '價格：從高到低',
    sort_by: '價格排序',
    reset: '重置',
    nft_name: 'NFT 名稱',
    state: '狀態',
    holder: '持有者地址',
    state_on_sale: '在售中',
    state_bidding: '去出價',
    state_under_bid: '出價中',
    collect: '收起',
    click_to_see_more: '點擊查看繫列下每個NFT狀態'
  },
  make_offer: '去出價',
  load_more: '加載更多',
  search: '查詢...',
  searching: '搜索中...',
  no_results: '查無結果',
  network_err: '網絡不穩定，請稍後重試',
  recently_listed: '最近上市',
  all_status: '所有狀態',
  to_buy_now: '去購買',
  on_auction: '去拍賣',
  back: '返回',
  reconnect_socket: '服務器異常斷開，正在重新連接，請稍後...',
  connect_timeout: '連接失敗，請稍後重試。',
  request_timeout: '請求超時，請刷新重試。',
  price: '價格',
  price_impact: '價格影響',
  price_impact_warning: '價格影響警告',
  buy_price: '購買價格',
  sell_price: '銷售價格',
  price_impact_tips: '您的交易對該池的市場價格的影響。',
  price_impact_high: '價格影響過高',
  notice_board_msg: 'LP 客戶端已更新，請下載最新版本，否則客戶端將無法正常運行。',
  go_to_download: '去下載',
  deposit_msg: '前往 everPay ，將資產從其他鏈（如 Arweave，Ethereum，Moonbeam 等）充值至 everPay 上。 ',
  swap_urgent_close: '繫統維護中....',
  swap_urgent_close_msg: '工程師們在奮力維護。不要著急，我們很快回來！',
  select_no_token: '正在加载数据，请刷新或稍后重试。',
  audit_report: '審計報告',
  welcome_to_page_title: '無處不在的 P2P 兌換網絡和 0% 搶跑損失。',
  shutdown_announcement: '公告',
  shutdown_content1: 'Aweave 將發布 2.6 版本，本次硬分叉升級將於區塊高度 1,132,210 激活，預計時間為',
  shutdown_time: ' 2023 年 3 月 6 日 (UTC+8)',
  shutdown_content2: '。屆時 Permaswap 也將在該期間停機，待 Arweave 升級完成後再次開啟。',
  downtime_content1: 'Aweave 正在發布 2.6 版本，本次硬分叉升級將於區塊高度 1,132,210 激活，預計時間為',
  downtime_content2: '。待 Arweave 升級完成後，Permaswap 將再次開啟。',
  shutdown_enter: '我已知曉',
  price_high_low: '價格降序',
  price_low_high: '價格升序',
  exchange: '交易',
  launch: '啟動 App',
  run_node: '運行節點',
  tutorial: '教程',
  total_balance: '總資產',
  difference_usd: '輸入和輸出金額的美元價值之間的估值差異。',
  nft_info: {
    'confi-city-everpay': 'Conflux 是一條 Layer1 公鏈，跨越國界和協議連接去中心化的經濟，採用 PoW/PoS 混合運行的機制。它採用獨特的 "樹狀圖 "共識機制--動態區塊結構，在不犧牲去中心化和安全性的前提下，促進 3000-6000 TPS 的處理。作為中國唯一符合監管的，無需許可的公鏈，Conflux 為在亞洲本地或希望進入亞洲市場的項目提供支持。Conflux 的目標是連接去中心化經濟體，以加強全球 DeFi 生態繫統發展。',
    'ardrive-everpay': '這次拍賣是由 everPay 與 ArDrive 聯合推出。ArDrive 是建立在 Arweave 區塊鏈上的去中心化永久存儲應用。得益於 Arweave 所提供的足以改變行業規則的技術， 讓任何類型的數據可以保存至少 200 年，甚至更久。一旦妳最重要的文件通過 ArDrive 上傳，它們將成為妳永久的記憶。 來自 ArDrive 社區的 =XaviZedd  為此次拍賣創作了龍龜 NFTs。',
    'inner-motion': '數字藝術品牌「異星藝想」，是為滿足藝術家意誌能夠自由錶達的需要而誕生的。其所有藝術作品的創作靈感來源於將生活、色彩、聲音與神聖幾何概念之間的結合。 幾何存在於我們周圍的一切事物中，從最小的物體或生物到最大的天文力量，在形狀、比例和對稱性之間總是存在著平衡，運用幾何圖形的各種結構來為藝術品尋找內在的平衡即是「異星藝想」的創作理念。「異星藝想」在創辦以來，與來自中國和歐洲各地的 DJ 以及音樂節合作，通過社交媒體、藝術展覽和音樂品牌來分享其藝術作品。“享受生活，分享與關愛” 是「異星藝想」的創作哲學。',
    'nueps-microplants': 'Noelia Puig（1995年，西班牙）是一名數字內容創作者，使用筆名 nueps 進行藝術創作。對荒謬、如夢和超現實藝術作品的興趣，使她走上了創作的道路。nueps 擅長使用熒光色和數字處理把自然世界的事物轉化為高度抽象的版本。她創造了一個神奇的宇宙，從科幻的生物到最日常、平凡物體的重塑。',
    'year-of-the-tiger-nfts': 'BitKeep and everPay\'s new collection is ready to go! Someone born in a tiger year is kind, adventurous and enthusiastic. Happy year of the Tiger to you all!',
    breakdaonbdeverpay: '💥 BREAKING all the Boundaries & Getting entirely FREEDOM! 💥🐼 Breaking Panda is a Web 3 trendy art Ip, dynamic combination of 🎨 「cryto art」, 「visual & physical trendy art fashion brand」,🎮 「 immersive social AR experience」, 🎸 「generative music」, 「urban sculptures」 and 「community driven」🎋 . We grant Breaking Panda fans CC0 license and open intellectual property rights, you could do everything you want, such as nft recreation, physical commodity production.',
    oldgodsremastered: '',
    'winston-family': 'Winston is arweaves mascot and the smallest unit of the arweave token. everPay designers present the second batch of NFTs for Arweave Winston Family.',
    'winston-ever': 'Winston is arweaves mascot and the smallest unit of the arweave token. everPay designers present the first NFT for Arweave Winston.',
    'wonderland-and-the-funny-fellows': 'They once lived happily on the planet Wonderland. Now the doomsday is coming, will they survive?',
    'everpay-other': '',
    'The Crypto Slang Gang 2022': 'Akord 邀請了藝術家 Serge Seidlitz 為此次 NFT 拍賣活動創作了 Crypto Slang Gang 繫列的 9 個NFTs，以慶祝 Akord 協議 smartweave 新版的推出。Crypto Slang Gang 所包含的標誌性人物是由 Akord 所鑄造的第一個在 Arweave 上的原子 NFTs。 Serge Seidlitz 是知名經紀人機構 B&A 旗下藝術家，在他 20 年的職業生涯中，他曾為《紐約時報》、《華盛頓郵報》、《衛報》、英國航空公司、蘋果、索尼、三星、MTV、可口可樂、Uber 和 KFC 提供設計。',
    'arks-by-decent-land': '經過數百代人的尋覓，Arks 是最終首批 43 艘到達 Yowunas Maias 定居併開始生活的方舟。該繫列 NFT 將發行在以太坊上併在 Arweave 上進行托管。 Arks 繫列還是 decent.land 早期用戶的通行證 -- web3 dapps 的社會和身份協議。Arks 持有者將被列入白名單，可用於未來的 decent.land 、代幣和更多使用場景。除了在協議中的使用場景， decent.land 傳說的故事前奏將通過 Arks 來展開。每個 Ark 的元數據都包含了創作者兼藝術總監 Lee Tyrrell 的豐富背景故事，其特徵與敘述中的事件相互呼應。Jrdsctt 是 Arks 背後的視覺藝術家，他是一位多產的平面設計師和攝影師，以突出的美術 1/1 而聞名，併利用代碼操縱藝術進行創新。',
    'Bird Song': '《鳥鳴集》是以一繫列瀕危鳥類的歌聲為靈感製作的專輯，旨在提高人們保護鳥類的意識以及籌集資金。在以前的專輯取得巨大成功後，本次在 everPay 上推出了新的合輯包含九首鳥類歌曲。所有的歌曲將被永遠儲存在 Arweave上。 這個項目是由非營利組織 Shika Shika 和音樂藝術家 El Búho 進行策劃。全部收入將用於保護瀕危鳥類。本次音樂輯都發行在 Pianity 平臺之上，Pianity 是一個音樂 NFT 平臺，音樂家和社區成員聚集在此，創造、收集和交易限量版的歌曲。 Pianity 開創了一種全新的模式：讓所有人可免費收聽音樂，並將智能合約與音樂文件永久存儲在 Arweave 上，這使藝術家和他的聽眾之間有了更深的聯繫。'
  },
  trading: {
    title: '極限測試',
    total_rewards: '總獎勵',
    total_volume: '總交易量',
    my_volume: '我的交易量',
    my_volume_chare: '我的交易量佔比',
    my_est_rewards: '我的預估獎勵',
    address: '地址',
    est_rewards: '預估獎勵',
    tips: {
      title: '僅統計活動期間的交易數據。每 1 分鐘更新一次。',
      total_rewards: '周期內總獎勵量。',
      total_volume: '指定代幣對應交易對的交易量。',
      my_volume: '指定代幣對應交易對的交易量。',
      my_volume_chare: '我的交易量佔比 = 日交易量佔比之和 / 活動天數 *100%。',
      my_volume_chare2: '每 1 分鐘更新一次。僅為預估值，准確數據以最終結算為准。',
      my_est_rewards: '我的預估總獎勵= 總獎勵 x 我的交易量佔比；僅為預估值，准確數據以最終結算為准。'
    }
  },
  fifa_page: {
    disclaimers: '免責聲明',
    disclaimers_msg1: '1. 競猜票實行自願購買，凡購買者均被視為同意本條款；',
    disclaimers_msg2: '2. 競猜只有一次中獎機會，不能兼中兼得，請謹慎操作；',
    disclaimers_msg3: '3. 違規行為：在兌換競猜票過程中，如果用戶所用賬戶出現違規行為（如虛假交易，惡意兌換，以獲得競猜票）一經發現，則停止該賬戶的競猜行為；',
    disclaimers_msg4: '4. 免責：活動期間如出現不可抗力或情勢變更等情況（包括但不限於重大災害事件、活動收政府機關指令需停止舉辦或調整、活動遭受嚴重網絡攻擊或因系統故障需暫停舉辦），Permaswap 可以根據本活動實際舉辦情況對活動規則進行合理變動或調整，相關變動或調整，會在相關渠道公告；',
    disclaimers_msg5: '5. 活動解釋：活動最終解釋權歸 Permaswap 所有。',
    confirm: '確認',
    confitm_tips: '點擊確認即表示您同意 permaswap 的本條款',

    match_not_started: '比賽未開始',
    finals: '決賽',
    in_match: '比賽進行中',
    match_finish: '比賽已結束',

    not_started: '未開始',
    guessing: '競猜中',
    stop_guessing: '競猜結束',
    stop_swap: '兌換結束',
    swaping: ' 兌換中',
    win: 'Win🇦🇷 ',
    stop_swap_tips: '兌換獎勵已結束，未兌換的朋友們可通過 Discord ,Telegram  聯系 Permaswap 。',

    quiz_started: '競猜開始',
    quiz_end: '競猜截止',
    quiz_closed: '競猜結束',
    swap_end_in: '兌換截止',
    swap_end_in_tips: '兌換獎勵已結束，未兌換的朋友們可通過 Discord，Telegram  聯系 Permaswap。',

    prize_pool: '總獎池',
    prize_pool_tips: '本場比賽已投入的 USDC 總量。希望您開心！',
    contest_rules: '競猜規則',
    buy_token: '購買 USDC',

    quiz: '競猜',
    select_team: '選擇隊伍',
    team: '隊伍',
    quiz_not_started: '競猜未開始',
    quiz_guessing: '競猜',
    wait_swap: '待兌換',
    swap: '兌換',
    min_quiz_amount: '最小競猜額 {amount} $',
    max_quiz_amount: '最大競猜額 {amount} $',
    confirm_fifa_tips: '購買後，交易將無法撤回，請謹慎購買!',
    error: {
      err_invalid_time: '時間無效',
      err_invalid_address: '地址無效',
      err_invalid_token: 'token 無效',
      err_invalid_amount: '大於最大賣出量 150$',
      err_invalid_signature: '簽名無效',
      err_invalid_bundle: 'Bundle 無效',
      err_invalid_price: '價格無效'
    }
  },
  red_packet: {
    cover_title: '領取 ACNH!',
    cover_msg: '在 Permaswap 上體驗絲滑交易',
    welcome_msg: '🎉 ACNH 交易大賽，瓜分50AR！',
    everVision1: 'everVision 是專注於為 Web3 提供基礎設施的科技公司，以支持 10 億用戶進入 Web3 網絡作為願景，探索提高用戶體驗，降低開發⻔檻，增強網絡性能的全新 WEB3 開發範式。其推出的毫秒級金融支付協議 everPay，使用去中心化可信存儲為其提供安全與可驗證保障，為用戶打造接近傳統互聯網體驗的數字資產支付結算產品。',
    a_bank: 'A-Bank 應用是由亞洲數字銀行（Asia Digital Bank，持牌納閩投資銀行，註冊號：LL.16434）提供的，是融合傳統金融服務模式與數字金融服務理念的跨界超級錢包，致力於為個人和企業用戶提供高效、合法、安全、合規且便捷的創新數字金融服務。',
    go_ever: 'Go everPay',
    go_bank: 'Go A-Bank',
    claim: '領取',
    retry: '再試',
    claiming: '領取中...',
    experience_swap: '體驗交易',
    receiv_address: '收款地址',
    finished: '共 {total} 個紅包  {totalPrice} 枚 {symbolName}',
    no_finished: '已領取 {num}/{totalRed} 個，共 {amount}/{totalAmount} {symbolName}',
    next_luck: '手慢了，下次好運哦',
    not_start: '活動暫未開始',
    event_closed: '活動已結束',
    no_repeat_claim: '無法重複領取',
    hot_err: '領取火熱，請重試',
    error: {
      err_redpacket_not_found: '未找到该红包活动',
      err_redpacket_claimed: '紅包內餘額不足',
      err_redpacket_user_claimed: '無法重複領取',
      err_redpacket_invalid_claim: '無法領取紅包',
      err_invalid_address: '地址無效',
      err_invalid_salt: 'salt 無效',
      err_invalid_signature: '簽名無效',
      err_invalid_time: '時間無效',
      err_invalid_redpacket: '紅包無效',
      err_invalid_params: '參數無效',
      err_no_redpacket: '暫無紅包活動'
    }
  },
  penalty_rules_title: '警告',
  penalty_rules_msg: '該賬戶下，您的違規行為導致了 {num} 筆訂單交易失敗。直到 ',
  penalty_rules_msg2: ' 以後，您才能繼續使用該賬戶進行交易。 ',
  penalty_view_rules: '查看規則',
  penalty_confirm: '確認'
}
export default messages
