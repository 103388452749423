
import { permaMessage } from '@/components/Message'
import isString from 'lodash/isString'
import zhTW from '@/i18n/zh-TW'
import { StateBalanceItem } from '@/store/state'
import dayjs from 'dayjs'
import { BundleItem, ChainType, EverpayTransaction, Token } from '@/libs/everpay-js'
import { fromDecimalToUnit, genTokenTag, matchTokenTag, toBN } from './everpay-js/utils/util'
import { isProd } from '@/constants'
import capitalize from 'lodash/capitalize'

export const shortAddress = (address: string, count: number): string => {
  return `${address.slice(0, count)}...${address.slice(-count)}`
}

export const waitSeconds = async (seconds: number): Promise<void> => {
  // eslint-disable-next-line promise/param-names
  return await new Promise(resolve => {
    setTimeout(resolve, seconds * 1000)
  })
}

export const formatInputPrecision = (amount: string, precision: number): string => {
  if (amount === '' || amount === undefined || amount === null) return ''
  if (amount === '0') return '0'
  if (isString(amount) && !amount.includes('.')) return amount
  if (isString(amount) && amount.includes('.')) {
    const amountArr = amount.split('.')
    if (amountArr[1].length > precision) {
      if (precision > 0) {
        return `${amountArr[0]}.${amountArr[1].slice(0, precision)}`
      } else {
        return `${amountArr[0]}.`
      }
    }
  }
  return amount
}

// 获取小数部分开始 0 的个数
const getFractionalPart0Counts = (fractionalPart: string): number => {
  for (let i = 0; i < fractionalPart.length; i += 1) {
    if (fractionalPart[i] !== '0') {
      return i
    }
  }
  return fractionalPart.length
}

export const formatRate = (rate: string, numberCounts: number): string => {
  const [integerPart, fractionalPart] = rate.split('.')
  if (integerPart.length >= numberCounts) {
    return integerPart
  }
  if (fractionalPart?.length > 0) {
    const needReduceCounts = +integerPart === 0
      // 整数部分是 0，补足小数部分不是 0 前缀的数量
      ? -getFractionalPart0Counts(fractionalPart)
      : integerPart.length
    const fractionalPartUpdated = fractionalPart.slice(0, numberCounts - needReduceCounts)
    return `${integerPart}.${fractionalPartUpdated}`
  }
  return rate
}

export const formatChainTypeDisplay = (chainType: ChainType): string => {
  if (chainType === ChainType.bsc) {
    return 'BSC'
  } else if (chainType === ChainType.platon) {
    return 'PlatON'
  } else if (chainType === ChainType.everpay) {
    return 'everPay'
  } else {
    return capitalize(chainType === 'moon' ? (isProd ? 'moonbeam' : 'moonbase') : chainType.includes(',') ? chainType.split(',')[0] : chainType)
  }
}

export const getErrorMessage = (e: Error, t: any): string => {
  let msgKey = ''
  if (e.message.includes('User denied')) {
    msgKey = 'error.error_deny'
  } else if (e.message.includes('Network Error')) {
    msgKey = 'error.network_error'
  } else if (e.message in zhTW.new_swap) {
    msgKey = `new_swap.${e.message}`
  } else if (e.message in zhTW.error) {
    msgKey = `error.${e.message}`
  } else if (e.message in zhTW) {
    msgKey = e.message
  } else {
    const matchedEthereumPatterns = (/(\s|\S)+code=(\S+),/gi).exec(e.message)
    if (matchedEthereumPatterns?.[2] !== undefined) {
      const code = matchedEthereumPatterns[2]
      if (code in zhTW.error) {
        msgKey = `error.${code}`
      }
    }
  }

  return msgKey !== '' ? t(msgKey) : t(e.message)
}

export const handleErrorMsg = (e: Error, t: any): void => {
  permaMessage({
    showClose: true,
    message: getErrorMessage(e, t),
    type: 'error'
  })
}

// 充值 等待区块确认数量
export const getMinedWaitBlock = (chainType?: string): number => {
  switch (chainType) {
    case ChainType.ethereum:
      return 6
    case ChainType.moon:
      return 40
    case ChainType.conflux:
      return 100
    case ChainType.arweave:
      return 15
    case ChainType.bsc:
      return 15
    case ChainType.platon:
      return 15
    default:
      return 15
  }
}

interface BalanceStack {
  [symbol: string]: string
}
export const getBalanceStack = (balances: StateBalanceItem[]): BalanceStack => {
  return balances.reduce<BalanceStack>((memo, item) => {
    memo[item.symbol.toUpperCase()] = toBN(item.balance).toString()
    return memo
  }, {})
}

export const checkParentsHas = (classname: string) => {
  return (node: HTMLElement | null) => {
    while (node != null) {
      if (node?.classList?.contains(classname)) {
        return true
      }
      node = node.parentNode as HTMLElement | null
    }
    return false
  }
}

/**
 * we don't use https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString here
 * cause toLocalString will auto round bigNumber
 * but now we used too many times bigNumber's method, this may caused some performance problem.
 */
export const thousandCommas = (num: number | string, place: number = 4): string => {
  if (place < 0 || place > 20) {
    console.warn('max must be less than 20')
    return toBN(num).toString(10)
  }

  const n = toBN(num).toFormat(place, 1)
  /**
   *  小数位去零
   * 3.14159000 =>  3.14159
   * 3.00 => 3
   * 3.00012 => 3.00012
   * 3.0001200 => 3.00012
   * 31415 => 31,415
   */
  // return n.replace(/\.0+$/g, '').replace(/\.(.*[^0])0+$/g, '.$1'
  return n
}

// only format money
export const formatMoney = (value: number | string, place: number = 2): string => {
  return thousandCommas(value, place)
}

const mobileRE = /(android|bb\d+|meego).+mobile|armv7l|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series[46]0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i

const tabletRE = /android|ipad|playbook|silk/i

export const isMobileDevice = (opts?: any): boolean => {
  if (opts == null) opts = {}
  let ua = opts.ua
  if (ua == null && typeof navigator !== 'undefined') ua = navigator.userAgent
  if (ua?.headers != null && typeof ua.headers['user-agent'] === 'string') {
    ua = ua.headers['user-agent']
  }
  if (typeof ua !== 'string') return false

  let result = mobileRE.test(ua) || (opts?.tablet != null && tabletRE.test(ua))

  if (
    !result &&
    opts?.tablet != null &&
    opts?.featureDetect != null &&
    navigator?.maxTouchPoints > 1 &&
    ua.includes('Macintosh') &&
    ua.includes('Safari')
  ) {
    result = true
  }

  return result
}

export interface FormatedBundleInternalRecord {
  internalStatus: string
  formatTime: string
  items: Array<{
    from: string
    to: string
    amount: string
    symbol: string
  }>
}

export const getInternalRecordFromBundleTx = (
  tokens: Token[],
  record: EverpayTransaction,
  account: string
): FormatedBundleInternalRecord => {
  const dataObj = JSON.parse(record.data)
  const items = [] as any[]

  if (dataObj.bundle?.items?.length != null) {
    dataObj.bundle.items.forEach((item: BundleItem) => {
      const { from, to, tag, amount } = item
      const token = tokens.find((t: Token) => matchTokenTag(genTokenTag(t), tag)) as Token
      const formatedItem = {
        from,
        to,
        symbol: token.symbol,
        amount: formatInputPrecision(fromDecimalToUnit(amount, token.decimals), 5)
      }
      items.push(formatedItem)
    })
  }

  return {
    internalStatus: record.internalStatus,
    formatTime: dayjs(record.nonce).format('YYYY-MM-DD HH:mm:ss'),
    items
  }
}
export const goHTMLPosition = (id: string): void => {
  // 平滑前往 id 元素位置
  (document.getElementById('html') as HTMLElement).setAttribute('style', 'scroll-behavior: smooth')
  document.documentElement.scrollTop = (document.getElementById(id) as HTMLElement).offsetTop - 50;
  (document.getElementById('html') as HTMLElement).setAttribute('style', 'scroll-behavior: auto')
}
export const sessionScrollTop = (): void => {
  const scrollTop = document.documentElement.scrollTop
  sessionStorage.setItem('scrollTop', scrollTop.toString())
}
export const openLink = (link: string): void => {
  if (link.length > 0) {
    window.open(link)
  }
}
