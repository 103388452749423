const messages = {
  app: 'App',
  wallet: 'Wallet',
  bridge: 'Bridge',
  swap: 'Swap',
  sdk: 'SDK',
  light_paper: 'Light Paper',
  NFT_auction: 'NFT Auction',
  resources: 'Resources',
  explorer: 'Explorer',
  lang: 'English',
  done: 'Done',
  launch_app: 'Launch App',
  slogan: 'Real-time financial protocol for the blockchain',
  use_app_connect: 'Use { connectAppName } connected',
  account: 'everPay Account',
  products: 'Products',
  develop: 'Develop',
  v3_farm: 'Liquidity Mining',
  documentation: 'Documentation',
  support: 'Support',
  help_center: 'Help Center',
  privacy_policy: 'Privacy Policy',
  faqs: 'FAQs',
  about: 'About',
  time: 'Time',
  from: 'From',
  to: 'To',
  coming_soon: 'Coming Soon...',
  transfer: 'Transfer',
  transfer_on_everpay: 'Transfer on everPay',
  deposit: 'Deposit',
  depositing: 'Depositing',
  withdraw: 'Withdraw',
  withdrawing: 'Withdrawing',
  transactions: 'Transactions',
  successful: 'Successful',
  failed: 'Failed',
  internal_tx: 'Internal TX',
  swap_records: 'Swap Records',
  contacts: 'Contacts',
  connect: 'Connect Wallet',
  disconnect: 'Disconnect',
  connect_wallet: 'Choose Wallet',
  connect_wallet_info:
    'Connect an Ethereum, Arweave or Moonbeam wallet to deposit and manage funds.',
  asset: 'Asset',
  assets: 'Assets',
  assets_connect_msg: 'By connecting a wallet to deposit and manage assets.',
  receiver: 'Receiver',
  amount: 'Amount',
  balance: 'Balance',
  fee: 'Fee',
  copy: 'Copy',
  edit: 'Edit',
  delete: 'Delete',
  add: 'Add',
  modify: 'Modify',
  filter: 'Filter',
  all: 'All',
  all_currencies: 'All',
  transaction_type: 'Type',
  balance_mark: 'Balance: ',
  immediately_add: 'Immediately add',
  network: 'Network',
  choose_network: 'Choose Network',
  choose_wallet: 'Choose Wallet',
  choose_token: 'Choose Token',
  choose_contact: 'Choose Contact',
  spread_percent: 'Spread',
  insufficient: 'Insufficient balance',
  click_deposit: 'please deposit',
  click_details: 'learn more',
  complete: 'Successful',
  everpay_balance: 'Balance on everPay',
  chain_balance: 'Balance on {blockchain}',
  transfer_amount: 'Amount',
  receive_account: 'Receiver Account',
  send: 'Send',
  receive: 'Receive',
  chain_type: 'Chain',
  support_chain_type: 'Supported Chain',
  symbol: 'Symbol',
  max_amount: '(Max)',
  system_upgrading: "everPay's system is just upgrading, please wait a moment",
  myaccount: 'My Account',
  no_data: 'No data found',
  current_account_address: 'Current everPay account address',
  contact_exists: 'Contact address exists',
  no_contacts: 'The contact list is empty',
  edit_contact: 'Edit contact',
  add_contact: 'Add contact',
  delete_contact: 'Delete contact',
  contact_name: 'Contact name',
  contact_account: 'Contact address',
  new_address_detected: 'New address detected! Add contact',
  edit_success: 'Edit was successful!',
  add_success: 'Add was successful!',
  delete_success: 'Delete was successful!',
  sign_message: 'Sign Message',
  confirm: 'Confirm',
  cancel: 'Cancel',
  total_cost: 'Total Cost',
  swap_out_amount: 'Pay Amount',
  swap_in_amount: 'Receive Amount',
  rate: 'Rate',
  slippage: 'Slippage',
  swap_success: 'Swap successful',
  connect_success: 'Connect successful',
  disconnect_success: 'Disconnect successful',
  view_swap_records: 'View swap records',
  swap_failed: 'Swap Failed',
  swap_refund: 'Refund',
  swap_slippage: 'Slippage Tolerance',
  not_found: 'Error 404',
  not_found_desc: 'Page Not Found',
  back_to_home: 'Back to homepage',
  transfer_receipt_support: 'Support ethereum, arweave account',
  withdraw_receipt_chaintype_support: 'Enter {chainType} address',
  quick_withdraw_mode: 'Quick Mode',
  quick_withdraw_mode_with_time: 'Quick Mode (estimated {time} seconds)',
  refunded: 'Refunded',
  quick_withdraw_mm: 'Quick Withdraw Market Maker: ',
  quick_withdraw: 'Quick Withdraw',
  quick_withdraw_data_not_inited: 'Data not initialized',
  quick_withdraw_long_time:
    'Quick withdrawals queue is too long, this feature has been suspended for now',
  quick_withdraw_refund: 'Refund',
  expected_receive: 'Expected to receive',
  actual_fee: 'Fee',
  actual_receive: 'Received',
  insufficient_quick_withdrawal: 'Insufficient Quick Withdrawal',
  token_insufficient_quick_withdrawal: 'Insufficient {token} Quick Withdrawal',
  quick_withdraw_info_url:
    'https://everpay.zendesk.com/hc/en-us/articles/4404465610137-everPay-Quick-Withdrawal',

  pls_not_transfer_to_exchange:
    '"Transfers" are done via the everPay network wallet, please do not transfer to exchanges or contract addresses, it will be lost!',
  pls_not_withdraw_to_exchange:
    'Please refrain from withdrawing assets to an exchange or contract address as they may be lost.',
  pls_use_ethereum_wallet:
    'Please install MetaMask or use Ethereum wallet to open',
  pls_install_arweave_wallet: 'Please install Arweave Wallet',
  pls_change_to_mainnet: 'Please Change Ethereum to Mainnet',
  pls_change_to_goerli: 'Please Change Ethereum to Goerli Testnet',
  copy_success: 'Copy Successful',
  introduction_to_everPay: 'Introduction to everPay',
  balance_info_url:
    'https://everpay.zendesk.com/hc/en-us/articles/4405069888537-What-is-everPay-balances-',
  total_assets_of: 'With total assets of',
  invalid_name: 'Invalid name',
  war_more_info:
    'Wrapped AR is an ERC-20 token backed 1:1 with AR, which means one wrapped AR is always equal to one AR',
  balance_more_info:
    'Your assets are stored on the everPay protocol. everPay is a separate protocol from other blockchains and can be thought of as a Layer2 or sidechain.',
  quick_mode_more_info:
    "The everPay team contacted external market maker teams and realized quick withdrawals through everPay transfers to save users' time and cost of withdrawals.",
  support_token_search: 'Support for Token Symbol Search',

  swap_page: {
    swap_detail: 'Swap Details',
    submitted: 'Submitted',
    confirm_swap: 'Confirm swap',
    view_more: 'For more information',
    banner_msg_title: 'No slippage, Instant execution!',
    banner_msg_tip: '100% Permanent 0% front running slippage.',
    banner_msg_title2: 'Join the swap and mint campaign!',
    banner_msg_tip2: '5 ANS for 5 lucky users.',
    banner_msg_title3: 'A new trading pair has been listed on our platform!',
    banner_msg_tip3: 'ARDRIVE/AR, start trading now.',
    banner_msg_title4: 'Earn trading fees by adding ARDRIVE/AR liquidity.',
    banner_msg_tip4: 'Join the LP whitelist now !'
  },
  error: {
    error_zero: 'The amount should be greater than 0',
    error_less_fee: 'The amount should be greater than fee',
    error_receiver: 'Wrong receiver address',
    error_receiver_chain_type: 'Wrong {chainType} address',
    error_transfer: 'Transfer failed',
    error_withdraw: 'Withdraw failed',
    error_deny: 'Transaction was rejected by user',
    deny_connect: 'User rejected the request',
    quick_withdraw_chain_type:
      'Quick withdrawals to {chainType} blockchain are not supported',

    less_then_zero: 'The amount should be greater than 0',
    invalid_address: 'Wrong receiver address',

    err_eth_tx_minted: 'Executed transaction',
    err_nil_amount: 'Nil amount',
    err_zero_amount: 'Zero amount',
    err_negative_amount: 'Negative amount',
    err_larger_amount: 'Amount too larger',
    err_larger_data: 'Data too larger',
    err_insufficient_balance: 'Insufficient balance',
    err_self_transfer: 'Transfer to yourself is not allowed',
    err_invalid_amount: 'Invalid amount',
    err_invalid_fee: 'Invalid Fee',
    err_invalid_tx_type: 'Invalid transaction Type',
    err_invalid_owner: 'Invalid owner',
    err_invalid_address: 'Invalid address',
    err_invalid_nonce: 'Invalid nonce',
    err_invalid_signature: 'Invalid signature',
    err_invalid_mint_tx: 'Invalid mint',
    err_invalid_locker: 'Invalid locker',
    err_invalid_tx_version: 'Invalid tx version',
    err_token_not_found: 'Token not found',
    err_invalid_chainid: 'Invalid chainID',
    err_tx_minted: 'Tx minted',
    err_negative_fee: 'Invalid Fee',
    err_invalid_action: 'Invalid Action',
    err_invalid_eth_tx: 'Invalid ETH Tx',
    err_invalid_erc20_tx: 'Invalid erc20 Tx',
    err_invalid_eth_address: 'Invalid ETH address',
    err_invalid_account_type: 'Invalid account type',
    err_nonce_too_low: 'Nonce too low',
    err_invalid_transaction: 'Invalid transaction',
    err_limit_exceeded: 'Requests are too frequent, please try again later',
    err_node_syncing: "Wait for system' synchronization to complete",
    err_system_upgrade:
      'The everPay system is just upgrading, pelease hold on a moment',

    // express
    err_no_enough_fee: 'Insufficient quick withdrawal fee',
    err_wallet_no_enough_fund:
      'Insufficient quick withdrawals for market makers',
    err_unsupport_withdraw_chain_type:
      'Quick withdrawals to {chainType} are not supported',
    err_unsupport_token: 'Not supported {token} Quick Withdrawal',

    // swap
    err_no_tx_data: 'no tx data',
    err_invalid_tx_data: 'tx data is invalid',
    err_invalid_app_id: 'app id is invalid',
    err_invalid_order: 'order data is not valid',
    err_invalid_amout: 'invalid tx in or out amount',
    err_no_order: 'no ordr posted',
    err_no_tx_type: 'no tx type',
    err_miss_swap_tx_data: 'miss swap tx data',
    err_no_pool: 'no available pool',
    err_invalid_token: 'invalid token',
    err_swap_race_condition: 'swap is race condition',
    err_everpay_tx: 'failed to post everpay tx',
    err_miss_parameter: 'miss parameter',
    err_unkown: 'unkown or unexpect swap error',
    err_insufficient_fund:
      'Insufficient market maker liquidity for this trade, pelease reduce the amount of swap',
    err_token_out_amount_too_small:
      'price changed, do not meet this amount of swap, please try again',
    error_invalid_order: 'invalid order',
    network_error: 'Network error, please try again later',
    err_lp_same:
      'Connection address is the same as LP node address, resulting in failed transactions. Please change the account.',
    // web3
    CALL_EXCEPTION: 'Call exception',
    INSUFFICIENT_FUNDS: 'Insufficient funds',
    NETWORK_ERROR: 'Ethereum network not matched',
    NONCE_EXPIRED: 'Nonce expired',
    REPLACEMENT_UNDERPRICED: 'Replacement underpriced',
    UNPREDICTABLE_GAS_LIMIT: 'Unpredictable gasLimit',

    INVALID_ACCOUNT_TYPE: 'Invalid account type'
  },
  new_swap: {
    // new swap
    err_not_found_path: 'Transaction Failed', // Not found path
    err_not_found_salt: 'Transaction Failed', // Not found salt
    err_not_found_lp: 'Transaction Failed', // Not found LP
    err_no_authorization: 'Authentication failed', // No authorization
    err_duplicate_registration: 'Duplicate Registration', // Duplicate registration
    err_invalid_msg: 'Transaction Failed', // Invalid message
    err_invalid_token: 'Invalid Token', // Invalid token
    err_invalid_order: 'Transaction Failed', // Invalid order
    err_invalid_address: 'Transaction Failed', // Invalid address
    err_invalid_signature: 'Transaction Failed', // Invalid signature
    err_invalid_paths_or_bundle: 'Transaction Failed', // Invalid paths or bundle
    err_invalid_pool: 'Invalid Pool', // Invalid pool
    err_no_pool: 'Invalid Pool', // No pool
    err_no_lp: 'Transaction Failed', // No LP
    err_no_path: 'Insufficient liquidity for this trade.', // Insufficient liquidity for this trade.
    err_invalid_amount: 'Enter Amount less than the minimum sell amount', // Invalid amount
    err_out_of_range: 'Out of Range', // Out of range
    err_invalid_number: 'Invalid Number', // Invalid number
    err_no_implement: 'Transaction Failed', // No implement
    err_invalid_path: 'Transaction Failed', // Invalid path
    err_invalid_swapouts: 'Transaction Failed', // Invalid swapouts
    err_invalid_pool_paths: 'Transaction Failed', // Invalid pool paths
    err_invalid_price: 'Invalid price', // Invalid price
    err_invalid_liquidity: 'Invalid liquidity', // Invalid liquidity
    err_invalid_price_direction: 'Invalid price direction', // Not found salt
    err_invalid_fee: 'Invalid fee', // Invalid fee
    err_invalid_swap_user:
      'The transaction may fail if the connection address is the same as the LP node address. Please switch accounts.',
    err_invalid_swap_lpuser: 'Transaction failed: Connection address is the same as LP node address.',
    err_no_lp_msg: 'The transaction is hot, please try again later.',
    err_blacklisted: 'The account is abnormal, please try again later.'
  },

  PST_WITHDARW_TO_ARWEAVE_MUST_BE_INTEGER:
    'PST Token withdrawals to Arweave must be integers',
  // NFT
  nft_error: {
    err_invalid_appId: 'Invalid appId',
    err_invalid_action: 'Invalid action',
    err_bid_self:
      'You already have your current NFT, no need to take another one',
    err_invalid_referredBy: 'Invalid referrer address',
    err_invalid_nftRecipient: 'NFT collection address is invalid',
    err_invalid_tx: 'Invalid transaction',
    err_invalid_nftWallet: 'Invalid artist wallet address',
    err_invalid_amount: 'Invalid bid amount',
    err_invalid_token: 'Invalid token',
    err_invalid_path: 'Invalid path',
    err_not_found_auctionId: 'Missing NFT auction batches',
    err_not_found_nftId: 'Missing NFT ID',
    err_not_during_event: 'Auction time does not match',
    err_not_recaptcha: 'Lack of validation',
    err_more_than_bidLimit:
      'Cannot bid on more than {bidLimit} NFTs at the same time',
    err_bid_too_low:
      'Bids must not be less than 5% of the total price, please re-bid',
    err_bid_too_high:
      'The bid exceeds the total price by 20%, please reduce the amount of the auction',
    err_more_than_ipLimit: 'Current IP is restricted, try again later',
    err_recaptcha_failed: 'Verification failed, please try again!',
    err_add_price:
      'Sorry, the price is no longer valid, the current price is {amount}, please re-add the price.'
  },
  service_not_available: 'the service is not available in your region.',

  nfts: 'NFTs',
  pool: 'Pool',
  fifa: 'FIFA🔥',
  nav_connect: 'Connect',
  whitepaper: 'Whitepaper',
  select_format: 'Select Format ',
  select_wallet: 'Select Wallet',
  change_account: 'Change',
  buy_now: 'Buy Now',
  replicated: 'Replicated !',
  connecting: 'Connecting...',
  transaction_records: 'Transaction Records',
  recent_orders: 'Recent Orders',
  transaction_success: 'Success',
  transaction_failed: 'Failed',
  transaction_time: 'Time',
  view_more: 'View More',
  no_more: 'No more',
  loading: 'Loading',
  select_token: 'Select a Token',
  no_result: 'No result',
  max: 'MAX',
  fetching_price: 'Fetching best price…',
  enter_amount: 'Enter an Amount',
  insufficient_balance: 'Insufficient {symbol} balance',
  price_updated: 'Price updated',
  accept: 'Accept',
  expected_output: 'Expected Output',
  waiting_confirmation: 'Waiting For Confirmation',
  swap_sign_message: 'Sign Message',
  more_about: 'More about',
  please_log: 'Please Connect wallet',
  no_data_order: 'No Data',
  pool_message:
    'Download the client, hold certified NFTs, run LP Nodes, and earn rewards!',
  download_windows: 'Download for Win',
  download_mac: 'Download for Mac',
  download_linux: 'Support for Linux deploy',
  deploy: 'Node Deploy',
  earn: 'Earn',
  my_nft: 'My NFT',

  nft: {
    certified_nft: 'Certified NFTs',
    certified_nft_desc:
      'everPay NFT holders can partake in Permaswap percursos mining.',
    discover_nft: 'Discover and collect Certified NFTs',
    discover_nft_desc:
      'Collect Certified NFTs (NFTs initial release in everPay auction), to participate in Permaswap mining!',
    discover_now: 'Discover Now',
    auction: 'On Auction',
    hot_nfts: 'Hot NFTs',
    view_more: 'View more',
    view_more_eth: 'More ETH NFT',
    view_more_ar: 'More AR NFT',
    price: 'Price',
    owned_by: 'Owned By',
    items: 'items',
    owners: 'owners',
    floor_price: 'floor price',
    tab_items: 'Items',
    tab_explore: 'Explore',
    nft_on_sale: 'NFTs on sale',
    price_low_to_high: 'Price: Low to High',
    price_high_to_low: 'Price: High to Low',
    sort_by: 'Sort by',
    reset: 'Reset',
    nft_name: 'NFT Name',
    state: 'State',
    holder: 'Holder Address',
    state_on_sale: 'On Sale',
    state_bidding: 'Bidding',
    state_under_bid: 'Under Bid',
    collect: 'Collect',
    click_to_see_more: 'You can expand and see information from each NFT'
  },
  make_offer: 'Make offer',
  load_more: 'Load more Items',
  search: 'Search...',
  searching: 'Searching...',
  no_results: 'No Results...',
  network_err: 'Network instability,Please try again later.',
  recently_listed: 'Recently listed',
  all_status: 'All status',
  to_buy_now: 'Buy Now',
  on_auction: 'On auction',
  back: 'Back',
  reconnect_socket:
    'The server is abnormally disconnected and is reconnecting, please wait...',
  connect_timeout: 'Connection failed, please try again later.',
  request_timeout: 'Request timed out. Please refresh and retry.',
  price: 'Price',
  price_impact: 'Price impact',
  price_impact_warning: 'Price impact warning',
  buy_price: 'buy price',
  sell_price: 'sell price',
  price_impact_tips:
    'The impact of your transactions on the market price of the pool.',
  price_impact_high: 'Price impact too high',
  notice_board_msg:
    'The Permaswap LP client has been updated. Please download the latest version, otherwise the client will not work properly.',
  go_to_download: 'Go to download',
  deposit_msg: 'Go to everPay and deposit on-chain assets such as Arweave, Ethereum, Moonbeam, etc. to everPay. ',
  swap_urgent_close: 'System maintenance is in progress ....',
  swap_urgent_close_msg: 'Our engineers are working hard. Don\'t worry. We’ll be back soon!',
  select_no_token: 'Loading data, please refresh or try again later.',
  audit_report: 'Audit Report',
  welcome_to_page_title: 'Say goodbye to gas fees and delays with 0% front running slippage DEX.',
  shutdown_announcement: 'Announcement',
  shutdown_content1: 'Aweave will release version 2.6, and this hard fork upgrade will be activated at block height 1,132,210, with an estimated time of ',
  shutdown_time: ' March 6, 2023 (UTC+8) ',
  shutdown_content2: '. Permaswap will also be shut down during that time and will be switched on again when the Arweave upgrade is complete.',
  downtime_content1: 'Aweave will release version 2.6, and this hard fork upgrade will be activated at block height 1,132,210, with an estimated time of ',
  downtime_content2: '.  Permaswap will be switched on again when the Arweave upgrade is complete.',
  shutdown_enter: ' I understand',
  price_high_low: 'Price descending',
  price_low_high: 'Price ascending',
  exchange: 'Exchange',
  launch: 'Launch App',
  run_node: 'Run a Node',
  tutorial: 'Tutorial',
  total_balance: 'Total Balance',
  difference_usd: 'The estimated defference between the USD values of input and output amounts.',
  nft_info: {
    'confi-city-everpay':
      'Conflux is a permissionless Layer 1 public blockchain connecting decentralized economies across borders & protocols and operates as a PoW/PoS hybrid chain. Built with a unique Tree-Graph consensus mechanism — a dynamic block structure is used to facilitate the processing of 3000–6000 TPS without sacrificing decentralization or security. As the only regulatory compliant, public, and permissionless blockchain in China, Conflux provides a unique advantage for projects building and expanding into Asia. As well as, Conflux aims to connect decentralized economies to strengthen the overall DeFi ecosystem globally. ',
    'ardrive-everpay':
      'The auction was jointly launched by everPay and ArDrive. ArDrive is built upon the Arweave blockchain, which provides game changing technology that enables any type of digital data to be stored for at least 200 years but probably much longer. Once your most important files are uploaded through ArDrive they will outlive you, your children, and your great-grandchildren. =Xavizedd from the ArDrive community created Dragon Turtle NFTs for the auction.',
    'inner-motion':
      'Alien Art is a Digital Art Label born from a need for free personal expression. All the inspirations come from life experiences, colors and sounds, but mostly from the concept of Sacred Geometry. Geometry is in everything around us, from the smallest object or living thing to the largest astronomical forces and there is always a balance between shapes, proportions and symmetries.Often in collaboration with DJs and Music Festivals around China and Europe, Alien Art is shared through social medias, art exhibitions and music labels. “Enjoy Life, Share & Care”. A series of animations inspired by one of the oldest form of art in human history, Mandala. Used as sacred guidance tool to help embark on a spiritual journey starting from the outside to the inner core. This collection embraces this ancient tradition and expresses a true balance between geometric shapes and precise movements.',
    'nueps-microplants':
      'Noelia Puig (1995, Spain) is a digital content creator who works under the pseudonym nueps. Her artistic process and her projects are defined by her interest in absurd, dream-like and surreal imagery. Using fluorescent colors and digitally manipulating highly abstracted versions of the natural (and human-made) world, nueps creates an alternate universe that ranges from fantastical-psychedelic creatures to reinventions of the most every day, mundane objects.',
    'year-of-the-tiger-nfts':
      "BitKeep and everPay's new collection is ready to go! Someone born in a tiger year is kind, adventurous and enthusiastic. Happy year of the Tiger to you all!",
    breakdaonbdeverpay:
      '💥 BREAKING all the Boundaries & Getting entirely FREEDOM! 💥🐼 Breaking Panda is a Web 3 trendy art Ip, dynamic combination of 🎨 「cryto art」, 「visual & physical trendy art fashion brand」,🎮 「 immersive social AR experience」, 🎸 「generative music」, 「urban sculptures」 and 「community driven」🎋 . We grant Breaking Panda fans CC0 license and open intellectual property rights, you could do everything you want, such as nft recreation, physical commodity production.',
    oldgodsremastered: '',
    'winston-family':
      'Winston is arweaves mascot and the smallest unit of the arweave token. everPay designers present the second batch of NFTs for Arweave Winston Family.',
    'winston-ever':
      'Winston is arweaves mascot and the smallest unit of the arweave token. everPay designers present the first NFT for Arweave Winston.',
    'wonderland-and-the-funny-fellows':
      'They once lived happily on the planet Wonderland. Now the doomsday is coming, will they survive?',
    'everpay-other': '',
    'The Crypto Slang Gang 2022':
      'Akord invited artist Serge Seidlitz to create a set of 9 NFTs to celebrate the launch of the new smartweave version of Akord Protocol. The Crypto Slang Gang was his creation, and these iconic characters will be the first Atomic Arweave NFTs minted by Akord! Serge Seidlitz is represented by global creative agency B&A. During his 20-year career, his clients have included, The New York Times, The Washington Post, The Guardian, British Airways, Apple, Sony, Samsung, MTV, Coca-Cola, Uber, and KFC.',
    'arks-by-decent-land':
      'Arks are a collection of the first 43 ships to reach Yowunas Maias after hundreds of generations of searching for a place to settle and build life. Hosted on Arweave and minted on Ethereum, Arks are early adopter passes for decent.land – a stack of social and identity protocols for web3 dapps. Ark holders are whitelisted for future decent.land collections, tokens, and more. Beyond utility in the protocol, Arks are a prelude to the story told so far through decent.land lore. Each ark’s metadata contains a rich backstory from writer and art director Lee Tyrrell, with traits based on events in the narratives. The visual artist behind Arks is jrdsctt, a prolific graphic designer and photographer known for glitchy fine art 1/1s and innovating on how art can be manipulated with code.',
    'Bird Song':
      'A Guide to the Birdsong is a series of albums inspired by the song of endangered birds, created to raise awareness and funds for their protection. After the huge success of previous sold-out collections, this new compilation launched on Everpay includes nine bird songs. It’s your chance to fight for their protection! All those bird songs will be stored for eternity on the Arweave blockchain. This project is curated by the non-profit organisation Shika Shika and the music artist El Búho. 100% of the revenues will be used to preserve endangered bird species. Pianity is a music NFT marketplace where musicians and their communities gather to create, collect, and trade songs in limited editions. Pianity’s pioneering approach which includes free listening for all and permanent storage of both the smart contract and the music files enables a deeper connection between the artist and his audience. Pianity is backed by Arweave – the next generation of decentralized networks dedicated to permanent data hosting.'
  },
  trading: {
    title: 'Stress Test',
    total_rewards: 'Total Rewards',
    total_volume: 'Total Volume',
    my_volume: 'My Volume',
    my_volume_chare: 'My Volume Share',
    my_est_rewards: 'My Est. Rewards',
    address: 'Address',
    est_rewards: 'Est. Rewards',
    tips: {
      title: 'Only count transaction data from the campaign period. It is updated every 1 minute.',
      total_rewards: 'Total rewards in this period.',
      total_volume: 'The trading volume of the token pair corresponds to a particular token.',
      my_volume: 'The trading volume of the token pair corresponds to a particular token.',
      my_volume_chare: 'My Volume Share = Sum of Daily Volume Share / Days of Activity * 100%.',
      my_volume_chare2: 'Updated every 1 minute. This is an estimate only. The exact data is subject to the final settlement.',
      my_est_rewards: 'My Est. rewards = Total reward * My volume share; This is an estimate only. The exact data is subject to the final settlement.'
    }
  },
  fifa_page: {
    disclaimers: 'Disclaimers',
    disclaimers_msg1: '1. the purchase of the competition tickets is voluntary and all purchasers are deemed to have agreed to these terms and conditions.',
    disclaimers_msg2: '2. only one chance to win the competition, no double win, please be careful.',
    disclaimers_msg3: '3. Violations: Any violation (e.g. fraudulent transactions, malicious redemptions to obtain tickets) in the process of redeeming tickets will result in the suspension of the user\'s account.',
    disclaimers_msg4: '4. Exclusion of liability: In the event of force majeure or change of circumstances (including, but not limited to, major disasters, suspension or adjustment of the event by order of government authorities, suspension of the event due to serious network attacks or system failures), Permaswap may make reasonable changes or adjustments to the rules of the event in accordance with the actual circumstances of the event and such changes or adjustments will be announced on the relevant channels.',
    disclaimers_msg5: '5. Event explanation: The final right to explain the event belongs to Permaswap.',
    confirm: 'Confirm',
    confitm_tips: 'By clicking “Confirm”, you agree to this Disclaimers',

    match_not_started: 'Match Not Started',
    finals: 'Finals',
    in_match: 'In Match',
    match_finish: 'Match Finish',

    not_started: 'Not Started',
    guessing: 'Guessing',
    stop_guessing: 'Stop Guessing',
    stop_swap: 'Stop Swap',
    swaping: ' Swaping',
    win: 'Win🇦🇷 ',
    stop_swap_tips: 'Redemption of rewards has ended, those who have not yet redeemed can contact Permaswap via Discord  or Telegram.',

    quiz_started: 'Quiz started',
    quiz_end: 'Quiz End In',
    quiz_closed: 'Quiz Closed',
    swap_end_in: 'Swap End In',
    swap_end_in_tips: 'Redemption of the prize has ended, those who have not redeemed can contact Permaswap via Discord or Telegram.',

    prize_pool: 'Prize pool',
    prize_pool_tips: 'The total amount of USDC invested in this game. Good luck, may the best win!',
    contest_rules: 'Contest Rules',
    buy_token: 'Buy USDC',

    quiz: 'Quiz',
    select_team: 'Select a Team',
    team: 'Team',
    quiz_not_started: 'Quiz Not Started',
    quiz_guessing: 'Guessing',
    wait_swap: 'Waiting for Swap',
    swap: 'Swap',
    min_quiz_amount: 'Minimum Quiz Amount {amount} $',
    max_quiz_amount: 'Maximum Quiz Amount {amount} $',
    confirm_fifa_tips: 'After purchase, the transaction will not be withdrawn, so please buy with caution!',
    error: {
      err_invalid_time: 'invalid time',
      err_invalid_address: 'Invalid address',
      err_invalid_token: 'Invalid token',
      err_invalid_amount: 'Greater than the maximum selling volume of 150$',
      err_invalid_signature: 'Invalid signature',
      err_invalid_bundle: 'Invalid bundle',
      err_invalid_price: 'Invalid price'
    }
  },
  red_packet: {
    cover_title: 'Claim ACNH! ',
    cover_msg: 'Experience silky trading on Permaswap',
    welcome_msg: '🎉 ACNH Trading Competition, Win a Share of 50 AR',
    everVision1: 'everVision is a technology company focused on providing infrastructure for Web3. With a vision to sustain 1 billion users on the Web3, everVision explores a new paradigm of Web3 development that improves the user experience, lowers the development threshold, and enhances web performance. Its millisecond financial payment protocol, everPay, uses decentralized trusted storage to provide security and verifiability, creating a digital asset payment and settlement product close to the traditional Internet experience for users.',
    a_bank: 'The A-Bank Super Wallet App, provided by Asia Digital Bank (licensed Labuan Investment Bank, registration number: LL.16434), is a crossover super wallet that integrates traditional financial service modes with digital financial service concepts. It is committed to providing efficient, legal, safe, compliant and convenient innovative digital financial services for individual and corporate users.',
    go_ever: 'Go everPay',
    go_bank: 'Go A-Bank',
    claim: 'Claim',
    retry: 'Retry',
    claiming: 'Claiming...',
    experience_swap: 'Experience Swap',
    receiv_address: 'Receiving Address',
    finished: '{total} Red Packet(s) with {totalPrice} {symbolName}',
    no_finished: 'Opened {num}/{totalRed}. {amount}/{totalAmount} {symbolName}',
    next_luck: 'Better luck next time!',
    not_start: 'The event has not yet started',
    event_closed: 'The event is over',
    no_repeat_claim: 'Cannot be claimed repeatedly',
    hot_err: 'Claim hot, Please try again.',
    error: {
      err_redpacket_not_found: 'The red packet activity was not found',
      err_redpacket_claimed: 'Insufficient balance in the red packet',
      err_redpacket_user_claimed: 'Cannot be claimed repeatedly',
      err_redpacket_invalid_claim: 'Unable to claim the red packet',
      err_invalid_address: 'Invalid address',
      err_invalid_salt: 'Invalid salt',
      err_invalid_signature: 'Invalid signature',
      err_invalid_time: 'Invalid time',
      err_invalid_redpacket: 'Invalid red Packet',
      err_invalid_params: 'Invalid params',
      err_no_redpacket: 'There is no red packet activity'
    }
  },
  penalty_rules_title: 'Warning',
  penalty_rules_msg: 'Under this account, your violation resulted in the failure of {num} order transactions. You will not be able to continue trading with this account until after ',
  penalty_rules_msg2: '.',
  penalty_view_rules: 'More Rules',
  penalty_confirm: 'Confirmation'
}
export default messages
